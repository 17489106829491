
$white: #FFF;
$grey: #EDEEF1;
$black: #101010;
$red1: #DF1F25;
$red2: #B70000;

//---BrakePoints---\\

$desktop-width: 1440px;
$mobile-width: 480px;

@mixin laptop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

//---fonts---\\

@font-face {
  font-family: "Soyuz Grotesk";
  src: url("./fonts/Soyuz Grotesk Bold.otf");
  //src: url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;500;600;700&display=swap');;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-VariableFont_wdth,wght.ttf");
}

@mixin Soyuz {
  font-family: "Soyuz Grotesk", sans-serif;
}

@mixin OpenSans {
  font-family: "OpenSans", sans-serif;
}
