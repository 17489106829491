@import "src/styles/variables";

.block {
  margin-top: 111px;
  display: flex;
  align-content: center;
  justify-content: center;
  @include laptop {
    margin-top: 7.71vw;
  }
  @include mobile {
    margin-top: 15.2vw;
  }

  &_wrapper {
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;

    padding: 71px 8px;
    @include laptop {
      gap: 2.43vw;
      padding: 4.93vw 0.56vw;
    }
    @include mobile {
      flex-direction: column-reverse;
      gap: 6.40vw;
    }
    .left {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 32px;
      @include laptop {
        gap: 2.22vw;
      }
      @include mobile {
        gap: 2.67vw;
        h1 {
          margin-bottom: 3.47vw;
        }
      }
      .row1 {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        @include laptop {
          gap: 1.11vw;
        }
        @include mobile {
          gap: 2.13vw;
        }
        &_block {
          width: 100%;
          display: flex;
          padding: 24px 28px 24px 24px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
          align-self: stretch;

          border-radius: 24px;
          background: #EDEEF1;
          transition: 0.5s ease;
          &:hover {
            cursor: pointer;
            background: #CECECE;
          }
          @include laptop {
            padding: 1.67vw 1.94vw 1.67vw 1.67vw;
            border-radius: 1.67vw;
          }
          @include mobile {
            border-radius: 3.73vw;
            padding: 3.47vw;
          }
        }
      }
      .row2 {
        display: flex;
        padding: 16px 16px 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 17px;

        border-radius: 24px;
        background: #EDEEF1;
        transition: 0.5s ease;
        &:hover {
          cursor: pointer;
          background: #CECECE;
        }
        @include laptop {
          padding: 1.11vw 1.11vw 1.11vw 1.67vw;
          border-radius: 1.67vw;
          gap: 1.18vw;
        }
        @include mobile {
          flex-direction: column;
          border-radius: 3.73vw;
          gap: 3.47vw;
          padding: 3.47vw;
        }
        &_block1 {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 18px;
          @include laptop {
            gap: 1.25vw;
          }
          @include mobile {
            width: 100%;
            gap: 2.33vw;
          }
          &_title {
            display: flex;
            align-items: center;
            gap: 10px;
            @include laptop {
              gap: 0.69vw;
            }
            @include mobile {
              gap: 5px;
            }
            img {
              @include laptop {
                width: 5.49vw;
                height: 5.49vw;
              }
              @include mobile {
                width: 12vw;
                height: 12vw;
              }

            }
          }
        }
        &_block2 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          width: 50%;
          @include laptop {
            gap: 0.56vw;
          }
          @include mobile {
            width: 100%;
            gap: 8px;
          }
          &_link {
            width: 100%;
            border-radius: 48px;
            background: #101010;
            max-height: 64px;
            display: flex;
            padding: 20px 41px 20px 24px;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            white-space: nowrap;
            @include laptop {
              border-radius: 3.33vw;
              max-height: 4.44vw;
              padding: 1.39vw 2.85vw 1.39vw 1.67vw;
              gap: 0.56vw;
              font-size: 1.11vw;
              line-height: 1.67vw; /* 150% */
              svg {
                width: 1.46vw;
                height: 1.46vw;
              }
            }
            @include mobile {
              border-radius: 11.20vw;
              max-height: 15.20vw;
              padding: 4.80vw 15.20vw 4.80vw 15.20vw;
              gap: 2.13vw;
              font-size: 3.73vw;
              line-height: 5.60vw; /* 150% */
              svg {
                width: 5.60vw;
                height: 5.60vw;
              }
            }
          }
        }
      }
    }

    .right {
      img {
        @include laptop {
          width: 42.29vw;
          height: auto;
        }
        @include mobile {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}