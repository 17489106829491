@import "variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  //border: 1px solid pink;
}

* {
  &::selection {
    background-color: rgba(144, 175, 227, 0.67);
  }
}

html {
  background-color: #fff;
  color: $black;

  width: 100%;
  scroll-behavior: smooth;

  scroll-margin-block-start: -111px;
  scroll-padding: 130px;
  @include laptop {
    scroll-padding: 8.72vw;
  }
}

button {
  border: none;
  background-color: rgba(0,0,0,0);
}
a {
  text-decoration: none;
  color: inherit;
}

.element-selector {
  font-weight: 200;
}

//---FONTS---\\

h1 {
  @include Soyuz;
  font-size: 90px;
  font-style: normal;
  font-weight: 700;
  line-height: 90px; /* 100% */
  @include laptop {
    font-size: 6.25vw;
    line-height: 6.25vw;
  }
  @include mobile {
    font-size: 13.33vw;
    font-style: normal;
    font-weight: 700;
    line-height: 51px; /* 102% */
  }
}
h2 {
  @include Soyuz;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 93.75% */
  @include laptop {
    font-size: 4.44vw;
    line-height: 4.17vw;
  }
  @include mobile {
    font-size: 8.53vw;
    font-style: normal;
    font-weight: 700;
    line-height: 8.80vw; /* 103.125% */
  }
}
h3 {
  @include Soyuz;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @include laptop {
    font-size: 2.64vw;

  }
  @include mobile {
    font-size: 5.87vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
h4 {
  @include Soyuz;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @include laptop {
    font-size: 1.67vw;
  }
  @include mobile {
    font-size: 5.87vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
h5 {
  @include OpenSans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @include laptop {
    font-size: 1.39vw;
  }
  @include mobile {
    font-size: 4.27vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
p {
  @include OpenSans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @include laptop {
    font-size: 1.25vw;
  }
  @include mobile {
    font-size: 3.73vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}



