@import "src/styles/variables";

header {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  padding: 30px 120px;
  border-radius: 141px;
  background: #000;
  margin: 0 8px;
  position: fixed;
  top: 20px;
  width: -webkit-fill-available;
  z-index: 100;
  @include laptop {
    padding: 2.08vw 8.33vw;
    border-radius: 10.67vw;
    margin: 0 1.87vw;
    top: 1.39vw;
  }
  @include mobile {
    justify-content: space-between;
    padding: 2.08vw 6.13vw;
    border-radius: 9.79vw;
    margin: 0 0.56vw;
    top: 1.39vw;
  }
  .left {
    display: flex;
    position: absolute;
    left: 120px;
    align-items: flex-end;

    justify-content: center;
    justify-items: center;
    gap: 15px;
    @include laptop {
      left: 8.33vw;
      gap: 1.04vw;
    }
    @include mobile {
      position: relative;
      left: 0;
      gap: 1.87vw;
    }
    .rzd {
      svg {
        width: 87px;
        height: auto;
        @include laptop {
          width: 6.04vw;
        }
        @include mobile {
          width: 14.40vw;
        }
      }
    }
    .and {
      svg {
        width: 40px;
        height: auto;
        @include laptop {
          width: 2.78vw;
        }
        @include mobile {
          width: 6vw;
        }
      }
    }
    .pushkeen {
      svg {
        width: 146px;
        height: auto;
        @include laptop {
          width: 10.14vw;
        }
        @include mobile {
          width: 21.87vw;
        }
      }
    }

  }
  .right {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    gap: 57px;
    color: $white;
    @include laptop {
      gap: 3.96vw;
    }
    @include mobile {
      svg {
        width: 6.40vw;
      }
    }

    a {
      position: relative;
      white-space: nowrap;
      transition: 0.5s;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
.burger {
  width: 100%;

  transition:
          background-color 0.5s ease,
          backdrop-filter 0.5s ease,
          height 0s;
  position: fixed;
  top: 0;
  z-index: 101;
  //overflow: hidden;
  .links {
    display: grid;
    padding: 20vw 4vw;
    background: #B70000;
    color: $white;    transition: 0.3s ease;
    transition-delay: 0.3s;
    overflow: hidden;
    .close {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin: -5vw;
    }
    .line {
      width: 100%;
      height: 0.3px;
      background-color: rgba(255, 255, 255, 0.5);
    }
    a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4.27vw;
      width: 50%;
        @include mobile {
          width: 100%;
          gap: 4.27vw;
        }
        .download_link {
          width: 100%;
          border-radius: 11.20vw;
          background: #101010;
          max-height: 15.20vw;
          display: flex;
          flex-direction: row;
          padding: 4.80vw 15.20vw 4.80vw 15.20vw;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
          white-space: nowrap;
          @include mobile {
            border-radius: 11.20vw;
            max-height: 15.20vw;
            padding: 4.80vw 15.20vw 4.80vw 15.20vw;
            gap: 2.13vw;
            font-size: 3.73vw;
            line-height: 5.60vw; /* 150% */
            svg {
              width: 5.60vw;
              height: 5.60vw;
            }
          }
        }

    }

  }
}