@import "src/styles/variables";

.block {
  border-radius: 141px;
  background-color: $grey;

  margin: 32px 8px 16px 8px;
  display: flex;
  align-content: center;
  justify-content: center;
  @include laptop {
    border-radius: 9.79vw;
    margin: 2.22vw 0.56vw 1.11vw 0.56vw;
  }
  @include mobile {
    border-radius: 6.40vw;
    margin: 2.22vw 0.56vw 1.11vw 0.56vw;
  }

  &_wrapper {
    max-width: 1440px;
    width: 100%;
    display: flex;
    padding: 47px 120px;
    align-items: center;
    justify-content: center;
    @include laptop {
      padding: 7.64vw 8.33vw 3.26vw;
    }
    @include mobile {
      padding: 8.53vw 4.27vw;
    }
    .left {
      width: 100%;
      img {
        position: relative;
        right: 0;
        @include laptop {
          width: 100%;
          height: auto;
        }
      }
    }

    .right {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 32px;
      position: relative;
      @include laptop {
        gap: 2.22vw;
      }
      @include mobile {
        gap: 8.53vw;
        h2 {
          text-align: center;
        }
      }
      .buttons {
        display: flex;
        gap: 15px;
        @include laptop {
          gap: 1.04vw;
        }
        @include mobile {
          top: 153vw;
          width: 100%;
          flex-direction: column;
          overflow: hidden;
          transition: 0.5s ease;
          background: #E2E2E2;
          border-radius: 4.27vw;
          position: absolute;
        }
        button {
          padding: 8px 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 32px;
          transition: 0.5s ease;
          background: #E2E2E2;
          color: #9C9C9C;
          white-space: nowrap;
          svg {
            height: 100%;
          }

          &:hover {
            cursor: pointer;
            background: #CECECE;
          }
          @include laptop {
            border-radius: 2.22vw;
            padding: 0.56vw 1.67vw;
          }
          @include mobile {
            width: 100%;
            max-height: 10vw;
            border-radius: 6.40vw;
            padding: 2.13vw 4.27vw;
            justify-content: space-between;
          }
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        color: rgba(37, 37, 37, 0.80);
        p {
          min-height: 240px;
          @include laptop {
            min-height: 16.67vw;
          }
          @include mobile {
            min-height: fit-content;
          }
        }
      }
    }
  }
}